import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import CodeIcon from "@mui/icons-material/Code";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GroupIcon from "@mui/icons-material/Group";

const ServicesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(8, 0),
}));

const ServiceCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: theme.spacing(3),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  borderRadius: "50%",
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const services = [
  {
    title: "High-Quality Development",
    description:
      "Experienced developers delivering robust and scalable solutions tailored to your business needs.",
    icon: <CodeIcon fontSize="large" />,
  },
  {
    title: "Competitive Pricing",
    description:
      "Top-tier software solutions at affordable rates, ensuring excellent value for your investment.",
    icon: <AttachMoneyIcon fontSize="large" />,
  },
  {
    title: "Timely Delivery",
    description:
      "Efficient project management ensuring on-time delivery without compromising on quality.",
    icon: <AccessTimeIcon fontSize="large" />,
  },
  {
    title: "Expert Consultation",
    description:
      "Seasoned professionals providing strategic advice to drive your business forward through technology.",
    icon: <GroupIcon fontSize="large" />,
  },
];

const Services: React.FC = () => {
  return (
    <ServicesContainer>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" gutterBottom color="primary">
          Our Services
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          At Codesultancys, we offer a range of services designed to provide
          high-quality software solutions at competitive prices.
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {services.map((service, index) => (
            <Grid item key={index} xs={12} sm={6} md={3}>
              <ServiceCard elevation={3}>
                <IconWrapper>{service.icon}</IconWrapper>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="center"
                  >
                    {service.title}
                  </Typography>
                  <Typography align="center">{service.description}</Typography>
                </CardContent>
              </ServiceCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ServicesContainer>
  );
};

export default Services;
