import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PhoneIcon from "@mui/icons-material/Phone";
import ComputerIcon from "@mui/icons-material/Computer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptIcon from "@mui/icons-material/Receipt";

const IndustriesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(8, 0),
}));

const IndustryCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-10px)",
  },
}));

const industries = [
  {
    title: "Banking & Fintech",
    description:
      "Secure, scalable financial applications that streamline operations and enhance customer experience.",
    icon: <AccountBalanceIcon fontSize="large" />,
  },
  {
    title: "Insurance",
    description:
      "Custom solutions to simplify claims processing, policy management, and compliance.",
    icon: <AccountBalanceWalletIcon fontSize="large" />,
  },
  {
    title: "E-commerce",
    description:
      "End-to-end platforms optimized for seamless shopping experiences and robust transaction processing.",
    icon: <ShoppingCartIcon fontSize="large" />,
  },
  {
    title: "Telecommunications",
    description:
      "Agile, real-time communication platforms and network management tools.",
    icon: <PhoneIcon fontSize="large" />,
  },
  {
    title: "Tech Houses",
    description:
      "Development and deployment of cutting-edge technologies, including cloud services, AI, and automation.",
    icon: <ComputerIcon fontSize="large" />,
  },
  {
    title: "Accountancy",
    description:
      "Software tailored to simplify accounting processes, reporting, and compliance for firms of all sizes.",
    icon: <ReceiptIcon fontSize="large" />,
  },
];

const Industries: React.FC = () => {
  return (
    <IndustriesContainer>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" color="primary" gutterBottom>
          Industries We Serve
        </Typography>
        <Typography variant="body1" align="center" paragraph>
          At Codesultancys, we take pride in our ability to deliver innovative
          and high-performing solutions across a broad spectrum of industries.
        </Typography>
        <Grid container spacing={4} sx={{ mt: 4 }}>
          {industries.map((industry, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <IndustryCard elevation={3}>
                <CardContent>
                  <Box display="flex" justifyContent="center" mb={2}>
                    {industry.icon}
                  </Box>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    align="center"
                  >
                    {industry.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    {industry.description}
                  </Typography>
                </CardContent>
              </IndustryCard>
            </Grid>
          ))}
        </Grid>
      </Container>
    </IndustriesContainer>
  );
};

export default Industries;
