import React from "react";
import { Box, Typography, Button, Container, Paper } from "@mui/material";
import { styled } from "@mui/system";
import EmailIcon from "@mui/icons-material/Email";

const ContactContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(8, 0),
  backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.main}10 25%, transparent 25%, transparent 50%, ${theme.palette.primary.main}10 50%, ${theme.palette.primary.main}10 75%, transparent 75%, transparent)`,
  backgroundSize: "40px 40px",
}));

const ContactPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(2, 4),
  fontSize: "1.2rem",
  fontWeight: "bold",
  borderRadius: "30px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
  },
}));

const Contact: React.FC = () => {
  const handleContactClick = () => {
    window.location.href = "mailto:contact@codesultancys.com";
  };

  return (
    <ContactContainer>
      <Container maxWidth="md">
        <ContactPaper elevation={3}>
          <Typography variant="h2" align="center" color="primary" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            Get in touch with us for any inquiries or to discuss your project
            needs.
          </Typography>
          <StyledButton
            variant="contained"
            color="secondary"
            startIcon={<EmailIcon />}
            onClick={handleContactClick}
          >
            Email Us
          </StyledButton>
        </ContactPaper>
      </Container>
    </ContactContainer>
  );
};

export default Contact;
