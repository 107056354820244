import React from "react";
import { Box, Typography, Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { Element } from "react-scroll";

const AboutContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  padding: theme.spacing(8, 0),
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `radial-gradient(circle, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
    transform: "translateY(0)",
    transition: "transform 0.5s ease-out",
  },
}));

const ParallaxBackground = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: 'url("/path/to/your/background-image.jpg")', // Replace with your image path
  backgroundSize: "cover",
  backgroundPosition: "center",
  opacity: 0.1,
});

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  position: "relative",
  zIndex: 1,
}));

const About: React.FC = () => {
  React.useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const aboutElement = document.getElementById("about-section");
      if (aboutElement) {
        const aboutPosition = aboutElement.offsetTop;
        const parallaxElement = aboutElement.querySelector(
          ".parallax-bg"
        ) as HTMLElement;
        if (parallaxElement) {
          const distance = scrollPosition - aboutPosition;
          parallaxElement.style.transform = `translateY(${distance * 0.3}px)`;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Element name="about-section">
      <AboutContainer id="about-section">
        <ParallaxBackground className="parallax-bg" />
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12} md={6}>
              <ContentPaper elevation={3}>
                <Typography variant="h2" color="primary" gutterBottom>
                  About Us
                </Typography>
                <Typography variant="body1" paragraph>
                  At Codesultancys, we specialize in delivering top-tier
                  software solutions at competitive prices. Founded by a team of
                  seasoned professionals with extensive experience as solutions
                  architects, engineering managers, and entrepreneurs, we bring
                  unparalleled expertise to the software consultancy industry.
                </Typography>
                <Typography variant="body1" paragraph>
                  Our team has worked with a diverse range of sectors, including
                  banking, fintech, insurance, e-commerce, telecommunications,
                  tech houses, and accountancy. Through these experiences, we've
                  identified a critical gap in the market: quality work often
                  comes at exorbitant costs, while cheaper alternatives
                  frequently lead to unsatisfactory results due to poor
                  communication and time zone misalignments.
                </Typography>
                <Typography variant="body1">
                  Codesultancys is here to change that. We're committed to
                  providing high-quality software development, leveraging
                  experienced developers who understand your business
                  needs—without the hefty price tag. We believe that affordable
                  doesn't have to mean compromised, and our goal is to deliver
                  robust solutions with excellent communication and
                  collaboration every step of the way.
                </Typography>
              </ContentPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <ContentPaper elevation={3}>
                <Typography variant="h3" color="primary" gutterBottom>
                  Our Mission
                </Typography>
                <Typography variant="body1" paragraph>
                  Our mission is to bridge the gap between quality software
                  development and affordability. By offering seasoned developers
                  with years of industry expertise at competitive rates, we aim
                  to provide businesses with sustainable, efficient, and
                  reliable tech solutions that meet their specific needs. We
                  strive to foster strong, long-term relationships with our
                  clients, helping them grow through technology that is
                  delivered on time and within budget.
                </Typography>
                <Typography variant="h3" color="primary" gutterBottom>
                  Our Vision
                </Typography>
                <Typography variant="body1">
                  Codesultancys seeks to become the go-to consultancy for
                  organizations that value both excellence and
                  cost-effectiveness. Our long-term goal is to build
                  partnerships that not only deliver exceptional software but
                  also drive innovation and long-term success for our clients.
                </Typography>
              </ContentPaper>
            </Grid>
          </Grid>
        </Container>
      </AboutContainer>
    </Element>
  );
};

export default About;
