import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import { styled } from "@mui/system";
import { Link } from "react-scroll";

const HeroContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main} 25%, ${theme.palette.primary.dark} 25%, ${theme.palette.primary.dark} 50%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.main} 75%, ${theme.palette.primary.dark} 75%, ${theme.palette.primary.dark} 100%)`,
  backgroundSize: "40px 40px",
  color: theme.palette.secondary.main,
  padding: theme.spacing(15, 0),
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.primary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },
  marginTop: theme.spacing(4),
  padding: theme.spacing(1, 4),
}));

const Hero: React.FC = () => {
  return (
    <HeroContainer>
      <Container maxWidth="md">
        <Typography variant="h1" component="h1" gutterBottom>
          Codesultancys
        </Typography>
        <Typography variant="h4" gutterBottom>
          Top-Tier Software Solutions at Competitive Prices
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Bridging the gap between quality and affordability in software
          development
        </Typography>
        <Link to="contact" smooth={true} duration={500}>
          <StyledButton variant="contained" size="large">
            Get Started
          </StyledButton>
        </Link>
      </Container>
    </HeroContainer>
  );
};

export default Hero;
