import React from 'react';
import { Box, Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import { Element } from "react-scroll";

// Define types for the project data
type Project = {
  title: string;
  overview: string;
  solution: string;
  impact: string;
  outcomes: string[];
};

// Sample data for each project
const projects: Project[] = [
  {
    title: "Bank X - Transforming Digital Infrastructure with Cloud Migration and Advanced Data Processing",
    overview: "Bank X, a leading financial institution, sought to advance its digital transformation by transitioning its entire data infrastructure to the cloud. The project’s key objectives were to improve operational efficiency, streamline data access, and enhance system scalability to meet the growing demands of modern banking.",
    solution: "We partnered with Bank X to migrate its vast data ecosystem onto the cloud, leveraging Azure for secure and scalable storage solutions. Our team worked closely with Bank X to design and implement an ETL (Extract, Transform, Load) framework, moving critical data into structured databases and a lakehouse environment to optimize both accessibility and organization. To meet the data processing needs, we developed custom Node.js adapters that seamlessly integrate with Bank X’s API layer, enabling real-time data interactions and providing robust support for its service operations. For batch processing, we built Python scripts in Databricks using Flask, which automates data management tasks and ensures that Bank X’s systems remain efficient and responsive.",
    impact: "Our cloud migration solution significantly improved Bank X’s operational efficiency, reducing both infrastructure costs and system downtime. With enhanced data accessibility and automated processes, Bank X is now better positioned to respond swiftly to client needs while maintaining secure and reliable digital services.",
    outcomes: [
      "Reduced operational costs and improved resource efficiency",
      "Increased data accessibility through a scalable cloud infrastructure",
      "Minimized downtime with reliable and automated data management processes"
    ],
  },
  {
    title: "Insurance Y - Unifying Digital Experience through Integration and Agile Development",
    overview: "Insurance Y, a prominent insurance provider, was striving to enhance its customer experience as part of an ongoing digitization effort. However, due to rapid growth through acquisitions, Insurance Y’s technical landscape had become fragmented, with disparate systems and software that were challenging to unify. Past development efforts on the customer portal had struggled to align with evolving requirements and schedules, leading to inconsistent results.",
    solution: "We engaged with Insurance Y to overhaul their customer portal project by implementing a test-driven development (TDD) approach, ensuring that every functionality was well-defined, tested, and ready for deployment. This shift allowed for clearer alignment between requirements and outcomes, reducing costly missteps and improving the development lifecycle. Our team also undertook a data layer project to consolidate data from multiple legacy and modern systems. By creating an abstraction layer, we unified various data sources to present a consistent, cohesive data language for the customer portal. This seamless integration provided Insurance Y’s clients with a smoother, more intuitive user experience and a reliable platform for managing their policies and claims.",
    impact: "Despite the complexities of working within a large organization, our structured approach brought about a cultural shift within Insurance Y, accelerating development cycles and improving collaboration across teams. The revamped customer portal now delivers a more cohesive, efficient experience, with a foundation that supports Insurance Y’s future digital ambitions.",
    outcomes: [
      "Enhanced development agility through test-driven practices",
      "Unified data layer providing consistent data across the customer portal",
      "Improved collaboration and accelerated change management",
      "Successful launch of an efficient, user-centered customer portal"
    ],
  },
  {
    title: "Company Z - Building Agility in Deal Modeling with a Frontline Engagement Tool",
    overview: "Company Z, a deal modeling company and Microsoft partner, sought to create a powerful tool for their customer engagement team to streamline the initiation and closing of deals with key commerce agreement partners. As a smaller organization, Company Z offered an exciting greenfield opportunity, though challenges emerged due to intersecting ideas and evolving design needs.",
    solution: "Leveraging our Azure expertise, we started quickly, developing an MVP to launch the project. However, as we delved into the data integrations with commerce partners, it became evident that our initial assumptions about data structures and workflows needed recalibration. The team at Company Z was highly engaged but brought specific tool preferences and approaches that occasionally conflicted with project requirements. We adapted swiftly by pivoting to a more agile approach, embracing a “fail fast, prototype faster” philosophy. This allowed us to adjust to the unique needs of Company Z and refine our development process to uncover the most effective paths forward. Throughout, we maintained a collaborative “holding hands” partnership, guiding Company Z’s team and enhancing their understanding of scalable, efficient development practices.",
    impact: "Despite initial challenges, we successfully delivered the project on time, providing Company Z with a front-line tool that empowers their customer engagement team to manage client interactions more effectively. This experience brought invaluable insights for both teams, reinforcing the importance of agility, open communication, and the flexibility to pivot based on real-world insights.",
    outcomes: [
      "Rapid prototyping and adaptive development to meet evolving project needs",
      "Increased agility in development cycles and responsiveness to client feedback",
      "A completed engagement tool that enhances Company Z’s client interaction capabilities",
      "Strengthened client relationship and shared experience that enhances future collaboration"
    ],
  },
];

const CustomersContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    padding: theme.spacing(8, 0),
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: `radial-gradient(circle, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
      transform: "translateY(0)",
      transition: "transform 0.5s ease-out",
    },
  }));
  
  const ParallaxBackground = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("/path/to/your/background-image.jpg")', // Replace with your image path
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 0.1,
  });

  const ContentPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    position: "relative",
    zIndex: 1,
  }));

// Define a functional component for displaying project details
const ProjectCard: React.FC<{ project: Project }> = ({ project }) => {
  return (
    <div className="project-card">
      <h2 className="title">{project.title}</h2>
      <div className="section">
        <h3>Overview</h3>
        <p>{project.overview}</p>
      </div>
      <div className="section">
        <h3>Solution</h3>
        <p>{project.solution}</p>
      </div>
      <div className="section">
        <h3>Impact</h3>
        <p>{project.impact}</p>
      </div>
      <div className="section">
        <h3>Outcomes</h3>
        <ul>
          {project.outcomes.map((outcome, index) => (
            <li key={index}>{outcome}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Main App Component
const Customers: React.FC = () => {
    React.useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const customersElement = document.getElementById("customers-section");
            if (customersElement) {
            const aboutPosition = customersElement.offsetTop;
            const parallaxElement = customersElement.querySelector(
                ".parallax-bg"
            ) as HTMLElement;
            if (parallaxElement) {
                const distance = scrollPosition - aboutPosition;
                parallaxElement.style.transform = `translateY(${distance * 0.3}px)`;
            }
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
        }, []);

  return (
    <Element name="customers-section">
      <CustomersContainer id="about-section">
        <ParallaxBackground className="parallax-bg" />
            <Container maxWidth="lg">
                <Grid container spacing={4} alignItems="stretch">
                    <Grid item>
                        <ContentPaper elevation={3}>
                        <h1>Previous Customers</h1>
                        <h4>We’ve partnered with diverse clients across industries, learning from each unique challenge to refine our expertise and deliver lasting impact. Each project is a testament to our commitment to innovation, adaptability, and collaboration.</h4>
                        <div className="projects-container">
                            {projects.map((project, index) => (
                            <ProjectCard key={index} project={project} />
                            ))}
                        </div>
                        </ContentPaper>
                    </Grid>
                </Grid>
            </Container>
        </CustomersContainer>
    </Element>
  );
};

export default Customers;
